body {
	margin: 0;
	background-color: #000;
	color: #fff;
	font-family: Monospace;
	font-size: 13px;
	line-height: 24px;
	overscroll-behavior: none;
}

a {
	color: #ff0;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

button {
	cursor: pointer;
	text-transform: uppercase;
}

#nav {
	position:absolute;
	height: 100px;
	bottom : 0px;
	width : 100%;
	display: none;
	
}

.skip {
	outline:none;
	position: absolute;
    cursor:pointer;
	width: 2%;
	margin : 1%;

	bottom : 0px;
	border: none;

	opacity : 0.5;

}


@media (max-aspect-ratio: 1/1) {
	.skip {
		width: 15%;
	}
  }

#previous {
	-webkit-transform: scaleX(-1);
  	transform: scaleX(-1);
	left : 0px;
}

#next {
	right : 0px;

}

